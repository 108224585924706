<template>
<div
  class="container-login"
  :style="`background-image: ${urlBackground}; `"
>
  <div
    class="container-inner"
    :style="`background-color: ${urlBackground ? 'rgba(0,0,0,0.3);' : ''};`"
  >
    <ModalNovoPainel/>
    <ModalNovoCadastro/>
    <svg
      v-if="!backgroundImage"
      class="wave wave01"
      width="555"
      height="460"
      viewBox="0 0 555 460"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0823674 388.522C-53.6566 359.613 -85.103 321.972 -84.4282 283.358C-84.2239 244.857 -51.4279 205.268 -46.2004 164.885C-40.579 124.07 -62.1323 82.0283 -47.2524 38.3134C-32.4491 -5.72038 19.1814 -51.8593 80.6096 -69.6588C142.432 -87.8903 213.975 -78.1013 277.486 -60.3104C340.921 -42.8383 395.458 -16.8193 442.183 20.1846C489.454 57.3944 528.519 106.021 477.773 133.059C426.951 159.777 286.395 165.226 225.812 215.54C165.23 265.854 184.698 361.353 156.531 399.492C128.288 437.311 53.2741 417.226 0.0823674 388.522Z"
        fill="#00E4A0"
        fill-opacity="0.05"
      />
    </svg>
    <svg
      v-if="!backgroundImage"
      class="wave wave02"
      width="502"
      height="370"
      viewBox="0 0 502 370"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M553.488 71.8322C607.748 99.7511 639.879 136.808 639.913 175.428C640.415 213.927 608.35 254.11 603.864 294.582C598.993 335.494 621.313 377.133 607.238 421.114C593.244 465.412 542.469 512.49 481.377 531.413C419.9 550.775 348.189 542.3 284.362 525.677C220.618 509.371 165.613 484.357 118.217 448.216C70.2711 411.88 30.3212 363.978 80.5625 336.014C130.886 308.368 271.319 300.342 330.968 248.925C390.618 197.508 369.402 102.382 396.864 63.7336C424.409 25.4024 499.779 44.109 553.488 71.8322Z"
        fill="#00E4A0"
        fill-opacity="0.05"
      />
    </svg>
    <div v-if="selectPainel === false"
      class="container h-100 align-items-center animationOpacity"
    >
      <div v-if="!existParams"  
        class="row justify-content-center"
        :class="{ 'pt-5': !selectPainel }"
      >
        <div
          class="login"
          :class="{ 'col-5 pt-5': !isMobile, 'col-10': isMobile }"
        >
          <div class="headerLogin" v-if="logo === null">
            <img src="@/assets/icons/logo-fake.svg" />
          </div>
          <div class="headerLogin" v-else>
            <img :src="logo" />
          </div>
          <div class="headerLogin mt-3 mb-3">
            <div class="title">{{ $t('login.header_login') }}</div>
          </div>

          <form @submit.prevent="onSubmit" novalidate class="formLogin">
            <div class="form-group">
              <b-form-group
                :label="$t('login.label_email')"
                label-for="name"
                class="labelxx"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="email"
                  name="email"
                  v-validate="'required|email'"
                  :placeholder="$t('login.placeholder_email')"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="!errors.has('email')">
                {{ $t('login.feedback_menssage_email') }}
              </b-form-invalid-feedback>
            </div>

            <div class="form-group">
              <b-form-group
                :label="$t('login.label_password')"
                label-for="name"
                class="labelxx"
              >
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  name="password"
                  v-validate="'required'"
                  :placeholder="$t('login.placeholder_password')"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="!errors.has('password')">
                {{ $t('login.feedback_menssage_password') }}
              </b-form-invalid-feedback>
            </div>

            <b-form-invalid-feedback :state="!error">
              {{ $t('login.error_menssage') }}
            </b-form-invalid-feedback>

            <div class="btn-login" v-if="!isMobile">
              <a @click="recuperarSenha" class="recuperar-senha">
                {{ $t('login.forgot_password') }}
              </a>
              <div></div>
              <Captcha v-if="!disableRecaptcha" ref="Captcha" @changeVerify="changeVerify">
                <button type="submit" class="btn-Acesso">{{ $t('login.start_session') }}</button>
              </Captcha>
              <button v-else type="submit" class="btn-Acesso">{{ $t('login.start_session') }}</button>
            </div>
            <div v-if="isMobile">
              <Captcha v-if="!disableRecaptcha" ref="Captcha" @changeVerify="changeVerify">
                <button type="submit" class="btn-Acesso btnToMobile">
                  {{ $t('login.start_session') }}
                </button>
              </Captcha>
              <button v-else type="submit" class="btn-Acesso btnToMobile">
                {{ $t('login.start_session') }}
              </button>
              <a @click="recuperarSenha" class="recuperar-senha">
                {{ $t('login.forgot_password') }}
              </a>
            </div>
            <div v-if="free_courses" class="mt-3">
              <button @click.prevent="modalCadastro" class="btn-Acesso btnToMobile">
                {{ $t('login.register') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="selectPainel === true"
      class="container h-100 align-items-center"
    >
      <div class="row justify-content-center mt-5">
        <div
          class="login"
          :class="{ 'col-5 mt-5': !isMobile, 'col-10': isMobile }"
        >
          <div class="headerLogin" v-if="logo === null">
            <img src="@/assets/icons/logo-fake.svg" />
          </div>
          <div class="headerLogin" v-else>
            <img :src="logo" />
          </div>
          <div class="headerLogin mt-3 mb-3">
            <div class="title">{{ $t('login.select_a_panel') }}</div>
          </div>
          <ListSites :isMobile="isMobile" :painels="Painels"></ListSites>
        </div>
      </div>
    </div>
    <FooterBase type="home" />
  </div>
</div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import ModalNovoPainel from "@/components/ModalNovoPainel.vue";
import ModalNovoCadastro from "@/components/ModalNovoCadastro.vue";
import FooterBase from "@/components/FooterBase.vue";
import Captcha from "@/components/security/Captcha.vue";
import ListSites from "@/components/login/ListSites.vue";

import SiteService from "@/services/resources/SiteService";
import GlobalFlagService from "@/services/resources/GlobalFlagService";

const serviceSite = SiteService.build();
const serviceGlobalFlag = GlobalFlagService.build();

export default {
  components: {
    ModalNovoPainel,
    ModalNovoCadastro,
    FooterBase,
    Captcha,
    ListSites,
  },
  data() {
    return {
      free_courses: false,
      submit: false,
      error: false,
      errorCaptch: false,
      captch: false,
      email: null,
      password: null,
      blogs: [],
      selectPainel: false,
      Painels: "",
      client: {
        width: 0,
      },
      logo: null,
      color: null,
      existParams: false,
      backgroundImage: false,
      urlBackground: "",
      captcha: "",
      disableRecaptcha: false
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getDisableRecaptchaGlobalFlag();
  },
  mounted() {
    this.existCookie();
    this.getParam();
    this.getLogobyDomain();
    this.insertTheme();
    this.$root.$emit("loadTheme");
    this.$root.$on("createsite", (data) => {
      this.Painels = data;
      // Cookies.set("blogsClub", JSON.stringify(data), { expires: 365 });
    });
  },
  methods: {
    modalCadastro(){
      this.$root.$emit("bv::show::modal", "modal-novo-cadastro", "#btnShow");
    },
    openGreenn() {
      window.location.href = process.env.VUE_GREENN_ADM_HOST+"?type=club";
    },
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    },
    existCookie() {
      if (
        Cookies.get("auth_greennCourse") &&
        Cookies.get("access_level") &&
        Cookies.get("memberAuth")
      ) {
        this.$router.push("/home");
      }
    },
    insertTheme() {
      const d = document;
      var theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light");
      } else {
        d.documentElement.setAttribute("data-theme", theme);
      }
      this.$root.$emit("loadTheme");
    },
    getParam() {
      var queryString = window.location.search;
      var tath = this;
      if (queryString !== "") {
        const urlParams = new URLSearchParams(queryString);
        const params = urlParams.get("rdr");
        const type = urlParams.get("type");
        if (params === "" || params === null || params === undefined) {
          return false;
        } else {
          var url = "";
          if (type === "" || type === null || type === undefined) {
            url = process.env.VUE_APP_API_HOST + `/member/auth/` + params;
          } else {
            url =
              process.env.VUE_APP_API_HOST +
              `/member/auth/` +
              params +
              "?type=" +
              type;
          }
          this.existParams = true;
          this.$root.$emit("loadOn");
          axios.get(url).then(function (res) {
            if (type === "" || type === null || type === undefined) {
              tath.$store.dispatch("blogSelected", res.data.payload);
            } else {
              var resp = res.data;
              var sites = res.sites;
              Cookies.set("memberAuth", resp.Auth, { expires: 365 });
              // Cookies.set("blogsClub", resp.sites, { expires: 365 });
              if (sites.length === 0) {
                tath.openNew();
                tath.$root.$emit("loadOff");
              } else if (sites.length === 1) {
                tath.$store.dispatch("blogSelected", sites[0]);
              } else {
                tath.$root.$emit("loadOff");
                //verificar se ja esta em um subdomain ou domain
                for (let i = 0; i < sites.length; i++) {
                  const subdomains = sites[i].subdomain;
                  const domains = sites[i].domains;
                  if (domains.length !== 0) {
                    for (let x = 0; x < domains.length; x++) {
                      const domain = domains[x].domain;
                      if (
                        domain === "https://" + window.location.host ||
                        domain ===
                          "https://" + window.location.host.split(".")[0] ||
                        domain ===
                          "https://" + window.location.host.split(".")[1] ||
                        domain ===
                          "https://" +
                            window.location.host.split(".")[0] +
                            window.location.host.split(".")[1]
                      ) {
                        tath.$store.dispatch("blogSelected", sites[i]);
                        return;
                      }
                    }
                  } else if (
                    subdomains === window.location.host.split(".")[0] ||
                    subdomains === window.location.host.split(".")[1] ||
                    subdomains ===
                      window.location.host.split(".")[0] +
                        window.location.host.split(".")[1]
                  ) {
                    tath.$store.dispatch("blogSelected", sites[i]);
                    return;
                  }
                }
                tath.selectPainel = true;
                tath.Painels = sites;
              }
            }
          });
        }
      }
    },
    recuperarSenha() {
      if (process.env.VUE_APP_TYPE === "DEV") {
        window.location.href =
          "http://" +
          window.location.host +
          "/set-password?tokenEditPassword=resetpassword";
      } else {
        window.location.href =
          "https://" +
          window.location.host +
          "/set-password?tokenEditPassword=resetpassword";
      }
    },
    openNew() {
      this.$root.$emit("bv::show::modal", "modal-new-painel", "#btnShow");
    },
    getLogobyDomain() {
      serviceSite
        .read("/login")
        .then((resp) => {
          const d = document;
          window.document.title = resp.site_name + " - Greenn.Club";

          if (resp.meta.free_courses === 'on') {
            this.free_courses = true;
          }

          this.logo = resp.meta.logo;
          
          if (resp.meta.logo !== null) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = resp.meta.logo;
          }
          if (resp.meta.theme_fixed_painel === "on") {
            var color = resp.meta.theme_fixed_painel_color;
            d.documentElement.setAttribute("data-theme", color);
            this.$root.$emit("loadsettheme");
          }
          if (resp.meta.main_color !== null) {
            this.color = resp.meta.main_color;
            d.documentElement.setAttribute("data-color", this.color);
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            );
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            );
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            );
          }
          if (resp.meta.login_background_image && resp.meta.login_background_image.cdn_url) {
            this.backgroundImage = true;
           
            var background = resp.meta.login_background_image.cdn_url;
            this.urlBackground = "url(" + background + ")";
          }
          this.$router.push({name: "Home"});
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   // this.$router.push('/config-domain')
          // }
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    selectedPainel(data) {
      this.$store.dispatch("blogSelected", data);
    },
    onSubmit(get) {
      if (get !== "get" && !this.disableRecaptcha) {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          var that = this;

          let data = {
            username: this.email,
            password: this.password,
            captcha: this.captcha,
          };
          this.$root.$emit("loadOn");
          this.$store
            .dispatch("loginRequest", data)
            .then((resp) => {
              var sites = resp.sites;
              Cookies.set("memberAuth", resp.Auth, { expires: 365 });
              // Cookies.set("blogsClub", resp.sites, { expires: 365 });
              if (sites.length === 0) {
                this.openNew();
                this.$root.$emit("loadOff");
              } else if (sites.length === 1) {
                this.$store.dispatch("blogSelected", sites[0]);
              } else {
                this.$root.$emit("loadOff");
                //verificar se ja esta em um subdomain ou domain
                for (let i = 0; i < sites.length; i++) {
                  const subdomains = sites[i].subdomain;
                  const domains = sites[i].domains;
                  if (domains.length !== 0) {
                    for (let x = 0; x < domains.length; x++) {
                      const domain = domains[x].domain;
                      if (
                        domain === "https://" + window.location.host ||
                        domain ===
                          "https://" + window.location.host.split(".")[0] ||
                        domain ===
                          "https://" + window.location.host.split(".")[1] ||
                        domain ===
                          "https://" +
                            window.location.host.split(".")[0] +
                            window.location.host.split(".")[1]
                      ) {
                        this.$store.dispatch("blogSelected", sites[i]);
                        return;
                      }
                    }
                  } else if (
                    subdomains === window.location.host.split(".")[0] ||
                    subdomains === window.location.host.split(".")[1] ||
                    subdomains ===
                      window.location.host.split(".")[0] +
                        window.location.host.split(".")[1]
                  ) {
                    this.$store.dispatch("blogSelected", sites[i])
                    return
                  }
                }
                this.selectPainel = true
                this.Painels = sites
              }
            })
            .catch((err) => {
              console.error(err)
              that.error = true
              that.$refs.Captcha.resetCaptcha()
              this.$root.$emit("loadOff")
            })
        }
      })
    },
    getDisableRecaptchaGlobalFlag(){
      serviceGlobalFlag.read('DISABLE_RECAPTCHA')
      .then(res => {
        this.disableRecaptcha = res.existis
        
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.heightList {
  height: 55vh;
  overflow: auto;
}

.heightList::-webkit-scrollbar {
  width: 7px;
}

.heightList::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 5px;
  height: 50px;
}

.toCenter {
  display: flex;
  justify-content: center;
}
.spaceSelectPainel {
  background: var(--backgroundcolor);
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.spacePainels {
  border: 0.5px solid #ededf0;
  border-radius: 3px;
  padding: 15px;
  transition: 0.3s;
  margin-bottom: 15px;
}

.spacePainels:hover {
  border: 0.5px solid #ededf0;
  padding: 15px;
}

.spaceSelectPainelMobile {
  width: 100%;
  background: var(--backgroundcolor);
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
}

.lineDiv {
  height: 0.5px;
  background: #ededf0;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
}
.flexData {
  margin-bottom: 20px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  .spaceImg {
    width: 60px;
    height: 60px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    margin-left: 20px;
    margin-bottom: 5px;
  }
  .descricao {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #5d5d5d;
    margin-left: 20px;
  }
}
.btn-Acesso {
  background: var(--maincolor);
  border-radius: 5px;
  padding: 14px 28px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
  transition: 0.3s;
}
.btn-Acesso:hover {
  transform: scale(1.05);
}
.formLogin {
  .btnToMobile {
    width: 100%;
    margin-bottom: 15px;
  }
  input {
    border: solid 1px var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    font-size: 14px;
    padding: 20px;
  }
  input:hover,
  input:focus {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    background: rgba(0, 228, 160, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }
  input:focus {
    font-weight: 600;
  }
  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
}
.headerLogin {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--fontcolor);
  }
}
.login {
  // background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.h-100 {
  height: 100vh;
}
.wave {
  position: fixed;
  z-index: -1;
  path {
    fill: var(--maincolor) !important;
  }
}
.wave01 {
  top: 0;
  left: 0;
}
.wave02 {
  bottom: 0;
  right: 0;
}
.btn-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recuperar-senha {
  color: var(--maincolor);
  font-weight: 600;
  cursor: pointer;
}
.recuperar-senha:hover {
  color: var(--maincolor);
}

.btnToGreenn {
  background: transparent;
  border: 0.5px solid #ededf0;
  color: var(--fontcolor);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-bottom: 10px;
  img {
    width: 65px;
    margin-left: -20px;
    margin-top: 2px;
  }
}

.orLogin {
  display: flex;
  align-items: center;
  margin: 13px 0;
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    color: #81858E;
    margin: 0 15px !important;
  }
  .line {
    width: 100%;
    height: 0.5px;
    background: #ededf0;
  }
}
.container-login {
  flex-direction: column; 
  display: flex; 
  min-width: 100%;
  background-position: center center; 
  background-size: cover; 
  background-repeat: no-repeat; 
  min-height: 100vh;
}
.container-inner {
  flex-direction: column; 
  display: flex; 
  min-width: 100%;
  background-position: center center; 
  background-size: cover; 
  background-repeat: no-repeat; 
  height: calc(100dvh - 80px)
 
 }
 
 @media screen and (max-width: 768px) {
   .container-inner {
     height: inherit;
     min-height: 100vh;
     margin-top: 3rem !important;
   }
 }
</style>